import instance from "../interceptors/AxiosInterceptor";

const BASE_URL = "/api/auth";
const OAUTH2_URL = "/api/oauth2";


const authService = {
    

    linkOauth2: async (email,password) => {
        try{
            const response = await instance.post(`${OAUTH2_URL}/linkAccountWithOAuth`,
            {
                username: email,
                password: password
            },
            {headers: {
                'content-type': 'application/json',
                'Accept': '*/*'
            },
            withCredentials: true
        });
        return response.data;
        }catch(error){
            
            throw error;
        }
    },
    

    login: async (email, password) => {
        try {
            const response = await instance.post(`${BASE_URL}/login`, {
                username: email,
                password: password
            }, {
                headers: {
                    'content-type': 'application/json',
                    'Accept': '*/*'
                },
                withCredentials: true
            });
            
            return response.data;
        } catch (error) {
            
            throw error;
        }
    },

    register: async (name, email, password,personType,telephoneNumber,IDNO) => {
        try{
            const requestBody = {
                name: name,
                username: email,
                password: password,
                telephoneNumber: telephoneNumber,
                personType: personType
            };
    
            // Conditionally include IDNO if personType is true
            if (personType) {
                requestBody.idno = IDNO;
            }
            const response = await instance.post(`${BASE_URL}/register`,requestBody, {
                headers: {
                    'content-type': 'application/json',
                    'Accept': '*/*'
                },
                withCredentials: true
            });
            
            return response.data;

        }catch(error){
            throw error;
        }
    },
    
    logout: async() => {
        try {
            const response = await instance.post(`${BASE_URL}/logout`, {}, { 
                withCredentials: true 
            });
            return response.data;
        } catch(error) {
            throw error;
        }
    },

    confirmEmailToken: async(token) => {
        try {
            const response = await instance.get(`${BASE_URL}/verification/confirm-email?token=${token}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    
    resendVerificationEmail: async (email) => {
        try {
            const response = await instance.post(`${BASE_URL}/verification/resend-email`,  email );
            return response.data;
        } catch (error) {
       
            throw error;
        }
    },
    
    resendPasswordToken: async (email) => {
        try {
            const response = await instance.post(`${BASE_URL}/verification/request-password-reset`,  email );
            return response.data;
        } catch (error) {
          
            throw error;
        }
    },

    requestPasswordReset: async (email) => {
        try{
            const response = await instance.post(`${BASE_URL}/verification/request-password-reset`, email);
            return response.data;
        }catch(error){
          
            throw error;
        }
    },

    passwordReset: async (token,password) => {
        try{
            const response = await instance.post(`${BASE_URL}/verification/reset-password`, {
                token:token,
                password:password
            });
            return response.data;
        }catch(error){
         
            throw error;
        }
    }
    ,
    requestChangeDetails: async () => {
        try {
            const response = await instance.post(`${BASE_URL}/verification/request-details-change`,{withCredentials:true});
            return response.data;
        } catch (error) {
            throw error;
        }
    }
    

};
export default authService;