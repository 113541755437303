import React from 'react'
import { useEffect } from 'react'
import { CircleSpinner } from 'react-spinner-overlay';
import { toast } from 'react-toastify';
import { useAuth } from '../context/AuthContext';
import subscriptionService from '../services/SubscriptionService'
import MyButton from '../UI/button/MyButton';
import FreeSubscriptionCard from './FreeSubscriptionCard';
import PaymentMethodCard from './PaymentMethodCard';
import SubscriptionCard from './SubscriptionCard';

export default function SubscriptionDetails({goToCheckoutForm, planId, setPlanId}) {
    const {authUser, loading} = useAuth();
    let startDate;
    let endDate;
    let nextBillingDate;
    let price;
    useEffect(() => {
        if (authUser && authUser.subscription && authUser.subscription.plan) {
            setPlanId(authUser.subscription.plan.id);
        }
    }, [authUser]);

    const handleCancelPlan = async () => {
        try{
            const data = await subscriptionService.cancelSubscription();
            toast.success('Plan anulat cu succes.', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            window.location.reload(false);
        }catch(error){

        }
    };
    if(authUser.subscription){
        endDate = new Date(authUser.subscription.lastBillingDay).toLocaleDateString('ro-RO', { year: 'numeric', month: 'numeric', day: 'numeric' });
        startDate = new Date(authUser.subscription.firstBillingDay).toLocaleDateString('ro-RO', { year: 'numeric', month: 'numeric', day: 'numeric' });
        nextBillingDate =  new Date(authUser.subscription.nextBillingDate).toLocaleDateString('ro-RO', { year: 'numeric', month: 'numeric', day: 'numeric' });
        price = authUser.subscription.plan ? authUser.subscription.plan.price : null;
    }
    return (
        !loading ?
        authUser.subscription ?
        <div className='subscription-details-container'>
            <SubscriptionCard
            price = {price}
            nextBillingDate = {nextBillingDate}
            startDate = {startDate}
            endDate = {endDate}
            planId={planId}
            />
           {authUser.savedCardNumber && authUser.subscription.paymentMethod === "maib" &&   <PaymentMethodCard
                cardNumber = {authUser.savedCardNumber}
            />}
    <div className='inline-wrapper'>
    
    <MyButton 
    body = {authUser.subscription.status == "active" || authUser.subscription.status == "pending" && authUser.subscription.paymentMethod === "maib" ?  "Schimbă abonamentul" : "Prelungește abonamentul"}
    type = "button"
    className="update-button"
    onClick = {goToCheckoutForm}
    />
   {authUser.subscription.status === "active"  && authUser.subscription.paymentMethod === "maib" && <MyButton 
    body = "Anulează abonamentul"
    type = "button"
    className = "cancel-button"
    onClick ={handleCancelPlan}
    />}
    </div>
        </div>
        : <div className='subscription-details-container'><FreeSubscriptionCard/></div>
        : <CircleSpinner/>
    );
}
