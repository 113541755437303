import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import LoginPage from '../pages/LoginPage'
import SignupPage from '../pages/SignupPage'
import ProtectedRoute from './ProtectedRoute'
import PublicRoute from './PublicRoute'
import { calculatorRoutes } from '.'
import DashboardPage from '../pages/DashboardPage'
import SubscribeSuccesPage from '../pages/SubscribeSuccesPage'

export default function AppRouter() {
  return (
    <Routes>
    <Route path="/login" element={
    <PublicRoute>
      <LoginPage 
      />
      </PublicRoute>} />
    <Route path="/register" element={
    <PublicRoute >
      <SignupPage />
    </PublicRoute>
    } />
    <Route path="/dashboard" element={
    <ProtectedRoute>
      <DashboardPage />
    </ProtectedRoute>
  } />
  <Route path="/subscribe-succes" element={
    <ProtectedRoute>
      <SubscribeSuccesPage />
    </ProtectedRoute>
  } />
  {calculatorRoutes.map(route => {
    return <Route path={route.path} Component={route.component} key={route.path}/>
  })}
   <Route path="*" element={<Navigate to="/penalitate" replace />} />
</Routes> 
  )
}
