import React from 'react'
import SidebarButton from './SidebarButton'
import generalIcon from '../assets/icons/icons8-dashboard-32.png';
import settingsIcon from '../assets/icons/icons8-tune-32.png';
import exitIcon from '../assets/icons/icons8-log-out-32.png';
import calcIcon from '../assets/icons/icons8-combo-chart-32.png';
import walletIcon from '../assets/icons/icons8-wallet-32.png';
import { useCheckoutOverlay } from '../context/CheckoutContext';
export default function Sidebar({logout,activeSection,handleSwitchSection}) {
    const { showCheckoutOverlay, handleCheckoutOverlay } = useCheckoutOverlay();
  return (
    <div className='sidebar'>
              <SidebarButton
                title={
                    <h3 className="profile-link-h3">General</h3>
                }
                description={
                    <p className="profile-link-p">Info</p>
                }
                icon={
                <img src={generalIcon} loading="lazy" alt="" className="w24" />
                }
                onClick={() => handleSwitchSection('general')}
                className={activeSection === 'general' ? 'active-button' : 'inactive-button'}
              />               
              <SidebarButton
                title={
                    <h3 className="profile-link-h3">Statistica</h3>
                }
                description={
                    <p className="profile-link-p">Calculele tale</p>
                }
                icon={
                    <img src={calcIcon} loading="lazy" alt="" className="w24" />
                }
                onClick={() => handleSwitchSection('calcule')}
                className={activeSection === 'calcule' ? 'active-button' : 'inactive-button'}
              />
               <SidebarButton
                title={
                    <h3 className="profile-link-h3">Portofel</h3>
                }
                description={
                    <p className="profile-link-p">Istoria tranzacțiilor, pachet activ</p>
                }
                icon={
                    <img src={walletIcon} loading="lazy" alt="" className="w24" />
                }
                onClick={() => handleSwitchSection('wallet')}
                className={activeSection === 'wallet' ? 'active-button wallet-section' : 'inactive-button wallet-section'}
              />
            <SidebarButton
                title={
                    <h3 className="profile-link-h3">Setări</h3>
                }
                description={
                    <p className="profile-link-p">Detalii profil, parola</p>
                }
                icon={
                    <img src={settingsIcon} loading="lazy" alt="" className="w24" />
                }
                onClick={() => handleSwitchSection('settings')}
                className={activeSection === 'settings' ? 'active-button' : 'inactive-button'}
              />
             
              <div className='sidebar-bottom'>
              <SidebarButton 
                title={
                    <h3 className="profile-link-h3 logout">Ieși din cont</h3>
                }
                icon={
                    <img src={exitIcon} loading="lazy" alt="" className="w24" />
                }
                onClick={logout} 
                className='logout'
                />
              </div>
              </div>
  )
}
